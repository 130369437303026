<template>
    <ul class="ul1">
        <li :class="step>=1 ? 'act':''" >
            <span class="num">1</span>
            <p>问题描述/严重性 >></p>
        </li>
        <li :class="step>=2 ? 'act':''">
            <span class="num">2</span>
            <p>智能推荐解决方案 >></p>
        </li>
        <li :class="step>=3 ? 'act':''">
            <span class="num">3</span>
            <p>详细资料 >></p>
        </li>
        <li :class="step>=4 ? 'act':''">
            <span class="num">4</span>
            <p>联系方式 >></p>
        </li>
    </ul>
    <div v-if="step==1" v-loading="loading" class="bgf padd20">
        <p class="col24 font14">问题内容描述</p>
        <ul class="sylform flex5 labw120 padt30 padb30">
            <li>
                <label for="">问题概要<em>*</em></label>
                <el-input v-model="param.problemProfile" placeholder="请输入问题的概要" class="w515" size="small" ></el-input>
            </li>
            <li class="li1">
                <label for="">请输入问题描述<em>*</em></label>

                <div class="imgli1">
                    <kindeditor
                    :content="param3.problemDescription"
                    width="515px"
                    height="300px"
                    @base64Flie="base64Flie($event,'problemDescription')"
                    id="kind1"
                    @on-content-change="onContentChange($event,'problemDescription')"
                    ></kindeditor>
                    
                </div>
            </li>
        </ul>
            
        <p class="col24 font14">问题发生在什么地方？</p>
        <ul class="sylform labw120 padt30">
            <!-- <li>
                <label for="" class="">问题类型<em>*</em></label>
                <el-select size="small" value-key="id" v-model="problemType" @change="cgeType" placeholder="请选择">
                    <el-option
                    v-for="item in typeL"
                    :key="item.id"
                    :label="item.title"
                    :value="item">
                    </el-option>
                </el-select>
            </li> -->

            <li>
                <label for="">所属模块<em>*</em></label>
                <el-cascader
                ref="mycasc"
                v-model="productNameVersion"
                :props="props"
                size="small"
                @change="getWtlx"
                placeholder="请选择"
                :options="nameBanben"
                ></el-cascader>
                
                <label for="" class="wauto">问题类型<em>*</em></label>
                <el-select 
                size="small" 
                value-key="id" 
                v-model="problemType" 
                @change="(val)=>{param.categoryId=val.id; param.workspaceId=val.workspaceId; param2.threeCategoryId=val.id; param.problemType = val.categoryName; txtL.push(val.categoryName)}" 
                placeholder="请选择">
                    <el-option
                    v-for="item in wtlxL"
                    :key="item.id"
                    :label="item.categoryName"
                    :value="item">
                    </el-option>
                </el-select>
            </li>

            <li>
                <label for="">客户服务号</label>
                <el-select size="small" v-model="param.csiNumber" clearable  placeholder="请选择">
                    <el-option
                    v-for="(item,index) in numL"
                    :key="index"
                    :label="item.csiNumber +'（'+ item.companyName+'）' "
                    :value="item.csiNumber">
                    </el-option>
                </el-select>
            </li>

            <li class="padt20">
                <label for=""></label>
                <div>
                    <el-button class="w90" type="primary" @click="next(2)" size="small" >下一步</el-button>
                    <el-button class="w68" @click="celall" size="small" >取消</el-button>
                </div>
            </li>
        </ul>
    </div>

    <div v-if="step==2" class="bgf padd20">
        <p class="col24 font16 padb30">问题类型：{{txtL.join(' > ')}}</p>
        <p class="col24 font14">该类型的问题推荐解决方案的知识文档</p>

        <div v-if="wordList.length>0">
            <ul class="padb10 padt20">
                <li v-for="(item,index) in wordList" :key="index" class="padb14">
                    <el-link :href=" '#/knowledgeInfo/'+item.id " target="_blank"  >{{index+1}}. {{item.docTitle}}</el-link>
                    <!-- <el-link href="https://element.eleme.io" >1. 如何理解OCP权益中的“成本优势”权益</el-link> -->
                </li>
            </ul>

            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="param2.pageNo"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="param2.pageSize"
            layout=" prev, pager, next, sizes, jumper"
            :total="total">
            </el-pagination>
        </div>

        <div v-else class="inbl padl40">
           <el-empty description="暂无相关文档"></el-empty>
        </div>

        <div class="padt40 padb20">
            <el-button class="w90"  @click="step=1;" size="small" >上一步</el-button>
            <el-button class="w90" type="primary" @click="step=3;" size="small" >下一步</el-button>
            <el-button class="w68" @click="celall" size="small" >取消</el-button>
        </div>
    </div>

    <div v-if="step==3" class="bgf padd20">
        <p class="col24 font16 padb10">问题类型：{{txtL.join(' > ')}}</p>
        <ul class="sylform padt30">
            <li class="li1">
                <label for="" class="wauto2">问题附件</label>
                <div>
                    <ul class="ulfile padb10">
                        <li v-for="(item,index) in fileL" :key="index" class="">
                            <span class="col63">{{item.name}}</span>
                            <span @click="delImg(index,'attachmentPath')" class="colred point">删除</span>
                        </li>
                    </ul>

                    <div class="positionr inbl">
                        <input type="file"  
                        @change="handleFile('image2','attachmentPath')" id="image2" class="hiddenInput"  />
                        <el-button type="primary" size="small" >添加附件</el-button>
                    </div>
                    <p class="col8f font12 padt4">支持.png、.jpg、.jpeg、.txt、.rar、.doc、.xls、.xlsx、.zip格式，最大不超过50M</p>
                    
                </div>
            </li>
            <li class="li1">
                <label for="" class="wauto2">附件注释</label>
                <el-input v-model="param3.attachmentDescription" 
                type="textarea" :rows="6" placeholder="请输入添加附件的注释" class="w515" size="small" ></el-input>
            </li>
            <li class="li1 padt40">
                <label for="" style="opacity: 0;" class="wauto2 colf ">附件注释</label>
                <div class=" padb20">
                    <el-button class="w90"  @click="step=2;" size="small" >上一步</el-button>
                    <el-button class="w90" type="primary" @click="step=4;" size="small" >下一步</el-button>
                    <el-button class="w68" @click="celall" size="small" >取消</el-button>
                </div>
            </li>
        </ul>
    </div>

    <div v-if="step==4" class="bgf padd20">
        <p class="col24 font16 padb30">系统与谁联系并了解工单的详细信息</p>
        <el-form
            ref="ruleForm"
            :model="param"
            :rules="rules"
            hide-required-asterisk
            class="demo-ruleForm"
            label-width="117px"
        >
            <el-form-item  prop="mainContact">
                <template #label>
                   <label for="" class="font12 col8f padr14">主要联系人<em class="colred">*</em></label>
                </template>
                <el-input v-model="param.mainContact" placeholder="请输入主要联系人" class="w240" size="small" ></el-input>
            </el-form-item>

            <el-form-item  prop="mainMobile">
                <template #label>
                   <label for="" class="font12 col8f padr14">手机号<em class="colred">*</em></label>
                </template>
                <el-input v-model="param.mainMobile" placeholder="请输入手机号" class="w240" size="small" ></el-input>
            </el-form-item>

            <el-form-item  prop="mainEmail">
                <template #label>
                   <label for="" class="font12 col8f padr14">邮箱号<em class="colred">*</em></label>
                </template>
                <el-input v-model="param.mainEmail" placeholder="请输入邮箱号" class="w240" size="small" ></el-input>
            </el-form-item>

            <el-form-item  prop="contactWay">
                <template #label>
                   <label for="" class="font12 col8f padr14">联系方式<em class="colred">*</em></label>
                </template>
                <el-select size="small" class="w240" v-model="param.contactWay" placeholder="请选择">
                    <el-option label="手机号" :value="1"></el-option>
                    <el-option label="邮箱" :value="2"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <ul class="sylform ">
            <!-- <li>
                <label for="">主要联系人<em>*</em></label>
                <el-input v-model="param.mainContact" placeholder="请输入联系人" class="w240" size="small" ></el-input>
            </li>
            <li>
                <label for="">手机号<em>*</em></label>
                <el-input v-model="param.mainMobile" placeholder="请输入手机号" class="w240" size="small" ></el-input>
            </li>
            <li>
                <label for="">邮箱号<em>*</em></label>
                <el-input v-model="param.mainEmail" placeholder="请输入邮箱号" class="w240" size="small" ></el-input>
            </li>
            <li>
                <label for="">联系方式<em>*</em></label>
                <el-select size="small" class="w240" v-model="param.contactWay" placeholder="请选择">
                    <el-option label="手机号" :value="1"></el-option>
                    <el-option label="邮箱" :value="2"></el-option>
                </el-select>
            </li> -->
            <li class="padt20">
                <label for=""></label>
                <div class=" padb20">
                    <el-button class="w90"  @click="step=3;" size="small" >上一步</el-button>
                    <el-button class="w90" type="primary"  size="small" @click="submit" >提交工单</el-button>
                    <el-button class="w68" @click="celall" size="small" >取消</el-button>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { addAttachment, threeCategoryKnowledgeList} from '@/utils/api1.js'
import { submitWorkOrder, getOneCategoty, getTwoCategory, nameVersion, customerServiceNo, problemCategory} from '@/utils/api2.js';

export default {
    data(){
        const checkMobile = (rule, value, callback) => {
            console.log(value);
            if (!value) {
                callback(new Error("请输入手机号！"));
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                callback(new Error("当前输入号码有误，请核对后再次进行输入"));
            } else {
                callback();
            }
        };
        const checkEmail = (rule, value, callback) => {
            console.log(value);
            const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
            if (!value) {
                callback(new Error("请输入邮箱号！"));
            } else if (!reg.test(value)) {
                callback(new Error("当前输入邮箱号有误，请核对后再次进行输入"));
            } else {
                callback();
            }
        };
        return{
            // props:{
            //     value: 'id',
            //     label: 'categoryName',
            //     children: 'twoCategoryList',
            // },
            nameBanben:[], //所属模块信息
            txtL:[], // 显示问题类型数组
            wtlxL:[], //问题类型
            productNameVersion:null,
            step: 1,
            loading: false,
            param:{
                problemDesImagePath: [], //图片地址
                attachmentPath: [],   //附件地址
                workOrderType: 1,
            },
            param3:{
                problemDescription:''
            },
            typeL:[],  //问题类型列表
            numL:[],  //客户服务号
            param2:{
                docType:'',
                engineerFlag:false,
                pageNo: 1,
                pageSize:5,
                threeCategoryId: '',
            },
            problemType:{},
            wordList:[],
            total:0,
            fileL: [],
            rules:{
                mainContact: [ { required: true, message: '请输入主要联系人！', trigger: 'blur', }, ],
                mainEmail: [ { validator: checkEmail, trigger: 'blur' }],
                mainMobile: [ { validator: checkMobile, trigger: 'blur' }],
                contactWay: [ { required: true, message: '请选择联系方式！', trigger: 'change', }, ],
            },

            props:{
                value: 'id',
                label: 'title',
                children: 'threeCategoryList',
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level } = node;
                    console.log(node);
                    console.log(level);
                    if(level==1){
                        const id  = node.data.id;
                        // console.log(id);
                        getTwoCategory(id).then(res=>{
                            console.log(res);
                            if(res){
                                res.map(val=>{
                                    val.leaf = true
                                })
                                resolve(res);
                            }
                        });
                    }
                }
            },

        }
    },
    mounted(){
        this.getCate();
        let userInfo = this.$store.state.userinfo;
        console.log(userInfo)
        this.param.mainContact = userInfo.username;
        this.param.mainMobile = userInfo.mobile;
        this.param.mainEmail = userInfo.email;
    },
    methods:{
        base64Flie (base64,key) {
            // console.log(base64,key)
            // this.param3[key] += "<img  src='"+base64+"' target='_blank' />"

            let pic = new FormData();
            pic.append('file',base64);
            // console.log(pic)

            addAttachment(pic,0).then(res=>{
                console.log(res);
                if(res){
                    this.param3[key] += "<a style='color:#337eff;' href='"+res.downloadPath+"' target='_blank' >"+res.lookPath+"</a>"
                }
            })
        },

        onContentChange (val,key) {
            // console.log(val,key)
            this.param3[key] = val;
            this.onHtmlChange(val)
            // this.$emit('update:text',this.contentHtml)
        },

        // 富文本的方法改变返回数据
        onHtmlChange(val) {
            let content = val;
            let imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
            let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
            let arr = content.match(imgReg)  // 筛选出所有的img
            let srcArr = []
            if (arr != null) {
                this.loading = true;

                for (let i = 0; i < arr.length; i++) {
                    let src = arr[i].match(srcReg)
                    // 获取图片地址判断是否是base64格式
                    if (src[1].indexOf('base64') == -1) {
                        this.loading = false;
                        this.param3.problemDescription = content.replace(/<\/?(img)[^>]*>/gi, "");

                        return
                    } else {
                        srcArr.push(src[1])
                    }
                }
                let formData = new FormData()
                srcArr.forEach(item =>  {
                    // 将base64转换成文件流
                    formData.append('file', this.base64toFile(item))

                    addAttachment( formData,0).then(res=>{
                        console.log(res);
                        if(res){
                            this.param3.problemDescription = content.replace(/<\/?(img)[^>]*>/gi, "<a style='color:#337eff;' href='"+res.downloadPath+"' target='_blank' >"+res.lookPath+"</a>");
                            // console.log(this.param3.problemDescription)
                            this.loading = false;
                        }else{
                            this.loading = false;
                        }

                    })

                })
            }
            
        },

        base64toFile (dataurl) {

            let filename = 'file'
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let suffix = mime.split('/')[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
            })
        },
        
        // 选择所属模块信息，获取问题类型
        getWtlx(val){
            console.log(val);
            let obj= this.$refs.mycasc.getCheckedNodes()[0].pathLabels;
            let wid = this.$refs.mycasc.getCheckedNodes()[0].parent.data;
            console.log( wid, obj );
            this.txtL = JSON.parse(JSON.stringify(obj)) ;
            this.param.productNameVersion = obj.join('>');
            this.param.workspaceId = wid.workspaceId;
            let id = val[1];
            this.problemType = {};
            problemCategory(id).then(res=>{
                console.log(res);
                if(res){
                    this.wtlxL = res;
                }
            })
        },

        next(num){
            console.log(num)
            if(num==2){
                const para = this.param;
                const para3 = this.param3;

                console.log(para, para3)

                // return;
                if(!para.problemProfile){
                    this.$message.warning('请输入问题概要！');
                    return;
                }else if(!para3.problemDescription){
                    this.$message.warning('请输入问题描述！');
                    return;
                }else if(!para.productNameVersion){
                    this.$message.warning('请选择所属模块！');
                    return;
                }else if(!para.problemType){
                    this.$message.warning('请选择问题类型！');
                    return;
                }
                // else if(!para.csiNumber){
                //     this.$message.warning('请选择客户服务号！');
                //     return;
                // }
                this.getWordList();
            }
            this.step = num;
        },

        getWordList(){
            console.log(this.param2)
            threeCategoryKnowledgeList(this.param2).then(res=>{
                console.log(res);
                if(res){
                    this.wordList = res.voList;
                    this.total = res.total;
                }
            });
        },

        cgeType(val){
            console.log(val)
            this.param2.threeCategoryId=val.id;
            this.param.problemType = val.title;
            this.param.categoryId = val.id;
            this.param.workspaceId = val.workspaceId;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.param2.pageSize = val;
            this.getWordList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.param2.pageNo = val;
            this.getWordList();
        },
        
        celall(){
            this.$confirm('退出编辑后，您编辑的内容将会被清除请提前做好编辑内容的备份哦', '您确定要退出编辑当前的工单么？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$router.go(-1)
            }).catch(() => {
            });
        },
        submit(){
            console.log(this.param);
            let str = JSON.parse(JSON.stringify(this.param));
            const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式

            if(!str.mainContact){
                this.$message.warning('请输入主要联系人！');
                return;
            }else if(!str.mainMobile){
                this.$message.warning('请输入手机号！');
                return;
            }else if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(str.mainMobile)){
                this.$message.warning('请输入正确的手机号！');
                return;
            }else if(!str.mainEmail){
                this.$message.warning('请输入邮箱号！');
                return;
            }else if(!reg.test(str.mainEmail)){
                this.$message.warning('请输入正确的邮箱号！');
                return;
            }else if(!str.contactWay){
                this.$message.warning('请选择联系方式！');
                return;
            }

            this.$confirm('工单提交后 平台对应的客服工程师将会为您解决对应的问题 请耐心等待', '您确定提交当前的工单么？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {

                str.attachmentPath = str.attachmentPath.join(',');
                str.problemDesImagePath = str.problemDesImagePath.join(',');
                console.log(str);

                submitWorkOrder(str,this.param3).then(res=>{
                    console.log(res);
                    if(res){
                        this.$message.success(res.message);
                        this.$router.push('/service')
                    }
                })
            }).catch(() => { });
        },

        // 多图片选择
        handleFile(id, key) {
			this.loading = true;
			let imgFile = document.getElementById(id).files[0];
			console.log(imgFile);

            let obj = {};

            if(id=='image2'){
                obj.name= imgFile.name;
            };
			
            let pic = new FormData();
            pic.append('file',imgFile);
            addAttachment(pic,0).then(res=>{
                console.log(res);
                if(res){
                    this.param[key].push(res.downloadPath);
                    obj.url = res.downloadPath;
                    if(id=='image2'){
                        this.fileL.push(obj);
                    };
                    this.loading = false;
                }
            })
			
        },

		// 删除图片
		delImg(idx,key){
            this.param[key].splice(idx,1);
            if(key=='attachmentPath'){
                this.fileL.splice(idx,1)
            }
		},

        getCate(){
            getOneCategoty(1).then(res=>{
                console.log(res);
                if(res){
                    this.nameBanben = res;
                }
            });

            customerServiceNo().then(res=>{
                console.log(res);
                if(res){
                    this.numL = res;
                    // if(res.length==0){
                    //     this.$message.warning('请前往账号中心绑定SCI号！')
                    // }
                }
            });

            // 获取所属模块信息
            // nameVersion().then(res=>{
            //     console.log(res);
            //     if(res){
            //         this.nameBanben = res;
            //     }
            // });
        }
    }
}
</script>
<style lang="scss" scoped>
.ul1{
    display: flex;
    height: 66px;
    font-size: 14px;
    color: #242424;
    font-family: MicrosoftYaHei;
    li{
        display: flex;
        align-items: center;
        padding-right: 30px;
        .num{
            width: 26px;
            height: 26px;
            background: #CBCBCB;
            display: flex;
            justify-content: center;
            line-height: 26px;
            border-radius: 50%;
            font-size: 16px;
            margin-right: 12px;
        }
    }
    >.act{
        color: #FF6A00;
        .num{
            background: #FF6A00;
            color: #fff;
        }
    }
}
.hiddenInput{
	opacity: 0;
	height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.ulfile{
    li{
        background: #F2F2F2;
        width: 515px;
        box-sizing: border-box;
        padding: 0 16px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-bottom: 10px;
    }
}
</style>